import { Component,
         Input                         } from '@angular/core';

import { Partner                       } from '@app/constants';
import { Integrations                  } from '@app/core/environment/environment.constants';
import { Integration as _Integration   } from '@app/core/environment/environment.types';

type Integration = {
  name?: string;
  icon?: string;
}

const RS: Integration = {
  name: 'Royal Schedule',
  icon: '/assets/rs-symbol-color.svg'
}

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: false
})
export class IconComponent {

  constructor () { }

  @Input()
  set value (partner: Partner | 'royal_schedule' | undefined | null) {

    let integration: Integration | undefined;
    if (partner == 'schoolsoft')     integration = Integrations['SchoolSoft'];
    if (partner == 'sdui')           integration = Integrations['Sdui'];
    if (partner == 'additio')        integration = Integrations['Additio'];
    if (partner == 'pupil')          integration = Integrations['Pupil'];
    if (partner == 'konecto')        integration = Integrations['Konecto'];
    if (partner == 'royal_schedule') integration = RS;

    this.icon = integration?.icon ?? '';
    this.name = integration?.name ?? '';
  }
  protected icon: string;
  protected name: string;
}
